<template>
  <div>
    <b-card>
      <b-card-header>
        <label
          v-t="'modules.bank_accounts.p_c'"
          class="section-label mb-1"
        />
        <b-card-text class="font-medium-5 mb-0">
          <b-button
            class="m-auto"
            variant="primary"
            :disabled="isLoading"
            @click="onClickAddNew"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span
              v-t="'modules.bank_accounts.actions.add_new'"
            />
          </b-button>
        </b-card-text>
      </b-card-header>
      <b-list-group
        ref="bankAccountsList"
        class="bank-accounts-list"
      >
        <b-list-group-item
          v-for="account in bankAccounts"
          :key="`bank_account_${account.id}`"
          class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              {{ account.bank.name }}

              <span>
                <b-badge
                  v-if="account.is_active"
                  v-t="`Active`"
                  pill
                  variant="light-success"
                />
                <b-badge
                  v-else
                  v-t="`Inactive`"
                  pill
                  variant="light-danger"
                />
              </span>
            </h5>
            <span>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-success"
                class="btn-icon"
                :disabled="isLoading"
                @click="onClickEdit(account.id)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon"
                :disabled="isLoading"
                @click="onClickDelete(account.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
          </div>
          <b-card-text class="mb-1">
            <p><strong>{{ $t('modules.bank_accounts.inputs.beneficiary_name') }}:</strong><span class="pl-25">{{ account.beneficiary_name }}</span></p>
            <p><strong>{{ $t('modules.bank_accounts.inputs.account_number') }}:</strong><span class="pl-25">{{ account.account_number || '-' }}</span></p>
            <p><strong>{{ $t('modules.bank_accounts.inputs.iban') }}:</strong><span class="pl-25">{{ account.iban || '-' }}</span></p>
          </b-card-text>
          <!--          <small>-->
          <!--            <span class="text-secondary pr-50">Status:</span>-->
          <!--            <b-badge-->
          <!--              v-if="account.is_active"-->
          <!--              v-t="`Active`"-->
          <!--              pill-->
          <!--              variant="light-success"-->
          <!--            />-->
          <!--            <b-badge-->
          <!--              v-else-->
          <!--              v-t="`Inactive`"-->
          <!--              pill-->
          <!--              variant="light-danger"-->
          <!--            />-->
          <!--          </small>-->
        </b-list-group-item>

        <b-alert
          v-if="bankAccounts.length === 0"
          variant="info"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span
              v-t="'modules.bank_accounts._messages.no_bank_accounts_found'"
              class="ml-25"
            />
          </div>
        </b-alert>
      </b-list-group>
    </b-card>

    <sidebar-add-new-bank-account
      :banks="banks"
      :item-id.sync="sidebarItemId"
      :is-sidebar-active.sync="isSidebarActive"
      @refetch-data="fetchItems"
    />
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BCardText, BCardHeader, BButton, BBadge, BAlert,
} from 'bootstrap-vue'
import SidebarAddNewBankAccount from '@/views/models/payment_methods/add/SidebarAddNewBankAccount.vue'
import { onUnmounted, ref } from 'vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import bankAccountStoreModule from '@/views/models/payment_methods/bankAccountStoreModule'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'

export default {
  components: {
    SidebarAddNewBankAccount,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardHeader,
    BCardText,
    BBadge,
    BAlert,
  },
  directives: {
    Ripple,
  },
  props: {
    banks: {
      type: Array,
      default: Array,
    },
    bankAccounts: {
      type: Array,
      default: Array,
    },
  },
  mounted() {
    if (this.$refs.bankAccountsList?.children.length > 2) {
      const heightPerItem = this.$refs.bankAccountsList.children[0].offsetHeight || 0
      if (heightPerItem) {
        this.$refs.bankAccountsList.style.maxHeight = `${heightPerItem * 2.75}px`
      }
    }
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'bank_accounts'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, bankAccountStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    const sidebarItemId = ref(null)
    const isSidebarActive = ref(false)
    const isLoading = ref(false)

    const fetchItems = () => {
      // ctx.refs.preview.reset() // TODO: When we enable this, replace 'ctx.refs.preview' with 'preview.value' and add a new ref called 'preview'
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          isLoading.value = false
          ctx.emit('update:bank-accounts', response.data.data)
        })
    }

    const {
      deleteItem,
    } = useModelDelete(
      STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        fetchItems()
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )

    const onClickAddNew = () => {
      isSidebarActive.value = true
    }
    const onClickEdit = accountId => {
      sidebarItemId.value = accountId
      isSidebarActive.value = true
    }
    const onClickDelete = accountId => {
      deleteItem(accountId)
    }
    return {
      isLoading,
      isSidebarActive,
      sidebarItemId,
      fetchItems,
      onClickAddNew,
      onClickEdit,
      onClickDelete,
    }
  },
}
</script>

<style>
.bank-accounts-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>
