<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5
        v-t="'modules.payment_methods._create.tabs.select_network'"
        class="mb-0"
      />
      <small
        v-t="'modules.payment_methods._create.tabs.select_network:description'"
        class="text-muted"
      />
    </b-col>
    <b-col md="12">
      <p>{{ $t('modules.payment_methods._messages.select_one_of_networks') }}</p>
      <b-list-group>
        <b-list-group-item
          v-for="network in value.networks"
          :key="network.code"
          :active="selectedNetwork === network.code"
          class="flex-column align-items-start cursor-pointer"
          variant="dark"
          @click="$emit('select-network', selectedNetwork = network.code)"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5
              class="m-1 text-black"
              :class="{
                'text-black': selectedNetwork !== network.code,
                'text-white': selectedNetwork === network.code
              }"
            >
              {{ network.name }}

              <img
                :src="NetworksLogos[network.code]"
                :alt="network.code"
                class="pl-3"
                height="50"
              >
            </h5>
            <small
              v-if="selectedNetwork === network.code"
              class="text-secondary"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
            </small>
          </div>
        </b-list-group-item>
      </b-list-group>

      <validation-provider
        name="Selected Payment Network"
        rules="required"
      >
        <input
          id="selectedNetwork"
          v-model="selectedNetwork"
          type="hidden"
          required
        />
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCol,
  BRow,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getLocaleCurrency } from '@core/utils/utils'
import { ref } from 'vue'
import { NetworksLogos } from '@/views/models/payment_methods/list/PaymentMethodItem.vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  emits: ['select-network'],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLocaleCurrency,
  },
  setup(props, ctx) {
    const selectedNetwork = ref(null)

    return {
      validationErrors: {},
      selectedNetwork,
      NetworksLogos,
    }
  },
}
</script>
