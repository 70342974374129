<template>
  <b-row>
    <b-col
      cols="12"
      class="mb-2"
    >
      <h5
        v-t="'modules.payment_methods._create.tabs.basic_settings'"
        class="mb-0"
      />
      <small
        v-t="'modules.payment_methods._create.tabs.basic_settings:description'"
        class="text-muted"
      />
    </b-col>
    <b-col md="12">
      <i-input
        id="itemName"
        ref="itemName"
        v-model="value.name"
        :errors="validationErrors.name"
        type="text"
        title="modules.payment_methods.inputs.name"
        description="modules.payment_methods.inputs.name:description"
        required
        :translatable="true"
      />

    </b-col>
    <!-- Extra charges not available currenctly in payment-gateways -->
    <template
      v-if="value.provider && !value.provider.name"
    >
      <b-col md="6">

        <validation-provider
          #default="{ errors }"
          :name="$t('modules.payment_methods.inputs.fixed_fees')"
          rules="required"
        >
          <b-form-group
            :label="$t('modules.payment_methods.inputs.fixed_fees')"
            label-for="fixed_fees"
          >
            <b-input-group
              :append="getLocaleCurrency()"
              :class="{'is-invalid': errors && errors.length}"
            >
              <b-form-input
                id="fixed_fees"
                v-model="value.fixed_fees"
                type="number"
                placeholder="5"
                step="1"
                required
                :state="errors.length > 0 ? false:null"
              />
            </b-input-group>
          <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
          </b-form-group>
        </validation-provider>

      </b-col>
      <b-col
        md="1"
        class="text-center"
      >
        <h2 class="pt-2">
          +
        </h2>
      </b-col>
      <b-col md="5">

        <validation-provider
          #default="{ errors }"
          :name="$t('modules.payment_methods.inputs.ratio_fees')"
          rules="required"
        >
          <b-form-group
            :label="$t('modules.payment_methods.inputs.ratio_fees')"
            label-for="ratio_fees"
          >
            <b-input-group
              append="%"
              :class="{'is-invalid': errors && errors.length}"
            >
              <b-form-input
                id="ratio_fees"
                v-model="value.ratio_fees"
                type="number"
                placeholder="15"
                step="1"
                required
                :state="errors.length > 0 ? false:null"
              />
            </b-input-group>
          <!--                      <small class="text-danger">{{ errors[0] }}</small>-->
          </b-form-group>
        </validation-provider>

      </b-col>
    </template>

    <b-col md="12">
      <i-input
        v-model="value.is_active"
        :errors="validationErrors.is_active"
        type="switch"
        title="inputs.is_active"
        description="inputs.is_active:description"
      >
        <span class="switch-icon-left">
          <feather-icon icon="CheckIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="XIcon" />
        </span>
      </i-input>
    </b-col>
  </b-row>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCol,
  BFormGroup, BFormInput, BInputGroup, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider } from 'vee-validate'
import { getLocaleCurrency } from '@core/utils/utils'

export default {
  components: {
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getLocaleCurrency,
  },
  setup(props, ctx) {
    const custompaymentMethodOption = {
      code: 'CUSTOM',
      name: null,
    }

    return {
      providerOptions: [custompaymentMethodOption],
      validationErrors: {},
    }
  },
}
</script>
