export default {
  namespaced: true,
  name: 'bank_accounts',

  translatedAttributes: [
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray: item => ({
    id: item.id,
    bank_id: item.bank?.id || null,
    bank_name_en: item.bank_name?.en || null,
    bank_name_ar: item.bank_name?.ar || null,
    ...item,
    // fixed_cost: item.fixed_cost,
    // ratio_cost: item.ratio_cost,
    // is_active: item.is_active,
    // ...
  }),

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    ...data,
    // payment_company: {
    //   code: data.paymentCompanyCode,
    //   label: data.paymentCompanyName,
    // },
    // name: {
    //   en: data.name_en,
    //   ar: data.name_ar,
    // },
    // notes: {
    //   en: data.notes_en,
    //   ar: data.notes_ar,
    // },
    // fixed_cost: data.fixed_cost,
    // ratio_cost: data.ratio_cost,
    // is_active: data.isActive,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    ...errors,
    // name: {
    //   en: errors.name_en,
    //   ar: errors.name_ar,
    // },
    // notes: {
    //   en: errors.notes_en,
    //   ar: errors.notes_ar,
    // },
    // fixed_cost: errors.fixed_cost,
    // ratio_cost: errors.ratio_cost,
    // is_active: errors.is_active,
  }),
}
