<template>
  <div>
    <payment-methods-list-loader
      v-if="isLoading"
    />
    <div
      v-else
      class="list-view product-checkout mt-0"
    >
      <payment-method-list
        :is-loading.sync="isLoading"
        :items="items"
        :suggestions="suggestions"
        :has-active-bank-accounts="!!bankAccounts.filter(acc => acc.is_active).length"
        @item-update="fetchItemsList"
      />
      <div>
<!--        <general-settings-->
<!--          :value="settings"-->
<!--        />-->
<!--        <preview-->
<!--          ref="preview"-->
<!--          :cities="cities"-->
<!--        />-->
        <bank-accounts-list
          ref="bankAccountList"
          :banks="banks"
          :bank-accounts.sync="bankAccounts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import PaymentMethodsListLoader from '@/views/loaders/ShippingMethodsListLoader.vue'
import store from '@/store'
import paymentMethodStoreModule from '@/views/models/payment_methods/paymentMethodStoreModule'
import paymentMethodList from './PaymentMethodList.vue'
import BankAccountsList from './BankAccountsList.vue'

export default {
  components: {
    PaymentMethodsListLoader,
    // SFC
    paymentMethodList,
    BankAccountsList,
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'payment_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, paymentMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)
    const items = ref([])
    const suggestions = ref([])
    const settings = ref({})
    const banks = ref([])
    const bankAccounts = ref([])

    const fetchItemsList = () => {
      // ctx.refs.preview.reset() // TODO: When we enable this, replace 'ctx.refs.preview' with 'preview.value' and add a new ref called 'preview'
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          isLoading.value = false
          items.value = response.data.data
          suggestions.value = response.data.suggestions
          settings.value = response.data.settings
          banks.value = response.data.banks
          bankAccounts.value = response.data.bank_accounts
        })
    }
    fetchItemsList()

    return {
      isLoading,
      items,
      suggestions,
      settings,
      banks,
      bankAccounts,

      // Actions
      fetchItemsList,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/shipping-management.scss';
</style>
