<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5
          class="mb-0"
        >
          {{ $t('modules.payment_methods._create.tabs.additional_settings') }}
        </h5>
        <small class="text-muted">{{ $t('modules.payment_methods._create.tabs.additional_settings:description') }}</small>
      </b-col>
      <b-col
        v-for="config in value.settings || []"
        :key="config.id"
        md="6"
      >
        <i-input
          :id="config.id"
          :ref="config.id"
          v-model="config.value"
          :errors="validationErrors.name"
          :type="config.type"
          :title="$t(`modules.payment_methods.inputs.${config.id}`)"
          placeholder=" "
          description=""
          :required="config.required"
        />
      </b-col>
      <b-col
        v-if="gatewayConfigs.length % 2"
        md="6"
      />
    </b-row>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'

import {
  BRow,
  BCol, BLink, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    value: {
      type: Object,
      required: false,
      default: Object,
    },
    gatewayConfigs: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  computed: {
  },
  mounted() {
  },
  setup(props, ctx) {
    return {
      validationErrors: {},
    }
  },
}
</script>
