import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import bankAccountModel from './bankAccountModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  deleteOne,
  updateAttribute,
} = useModelStoreModule(bankAccountModel)

export default {
  name: 'bank_accounts',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll,
    fetchOne,
    create,
    update,
    deleteOne,
    updateAttribute,
  },
}
