<template>

  <div class="checkout-items">
    <p
      v-t="`modules.payment_methods._list.active_methods`"
    />
    <payment-method-item
      v-for="item in items"
      :ref="`item_${item.code}`"
      :key="`item_${item.code}`"
      :item="item"
      :warning-alert="item.code === 'BANK' && item.isActive && !hasActiveBankAccounts ? $t('modules.bank_accounts._messages.no_active_bank_accounts_found') : null"
      @click-edit="itemId => handleMethodEdit(itemId)"
      @item-update="$emit('item-update')"
    />
    <template v-if="suggestions.length">
      <hr>
      <p
        v-t="`modules.payment_methods._list.add_more_payment_methods`"
        class="pt-1"
      />
      <payment-method-item
        v-for="item in suggestions"
        :key="`suggestion_${item.code}`"
        :item="item"
        @click-add="item => handleMethodAdd(item)"
      />
    </template>

    <wizard-modal-payment-method-settings
      :options="suggestions"
      :item-id.sync="modalItemId"
      :provider="modalProvider"
      @onSubmit="$emit('item-update')"
    />
  </div>

</template>

<script>
import {
  VBToggle, VBModal,
} from 'bootstrap-vue'
import { ref } from 'vue'
import PaymentMethodItem from '@/views/models/payment_methods/list/PaymentMethodItem.vue'
import WizardModalPaymentMethodSettings from '@/views/models/payment_methods/add/WizardModalPaymentMethodSettings.vue'

export default {
  components: {
    PaymentMethodItem,
    WizardModalPaymentMethodSettings,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  emits: ['item-update'],
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasActiveBankAccounts: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: false,
      default: Array,
    },
    suggestions: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  setup(props, ctx) {
    const isAddNewSidebarActive = ref(false)
    const modalItemId = ref(null)
    const modalProvider = ref(null)
    const handleMethodAdd = item => {
      modalItemId.value = null
      modalProvider.value = item.provider // The provider contained in payment_method resource, so we select the provider itself
    }
    const handleMethodEdit = itemCode => {
      modalItemId.value = itemCode
      modalProvider.value = null
    }
    return {
      handleMethodAdd,
      handleMethodEdit,
      // Sidebar
      isAddNewSidebarActive,
      modalItemId,
      modalProvider,
    }
  },
}
</script>

<style>

</style>
